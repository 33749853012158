import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Intercom from '@intercom/messenger-js-sdk';
import { ensureCurrentUser } from '../../util/data';

const IntercomMessengerComponent = props => {
  const { currentUser } = props;
  
  const user = ensureCurrentUser(currentUser);
  
  const [userHash, setUserHash] = useState(null);
  const [isIntercomLoaded, setIsIntercomLoaded] = useState(false);

  // Load Intercom script
  useEffect(() => {
    if (!window.Intercom) {
      window.intercomSettings = {
        app_id: 'poc51jwi'
      };
      
      // Create and append the Intercom loader script
      const script = document.createElement('script');
      script.src = 'https://widget.intercom.io/widget/poc51jwi';
      script.async = true;
      document.body.appendChild(script);

      // Check periodically if Intercom is loaded
      const checkIntercom = setInterval(() => {
        if (typeof window.Intercom === 'function') {
          setIsIntercomLoaded(true);
          clearInterval(checkIntercom);
        }
      }, 100);

      return () => clearInterval(checkIntercom);
    } else {
      setIsIntercomLoaded(true);
    }
  }, []);

  // Fetch the hash when we have a user
  useEffect(() => {
    const fetchHash = async () => {
      if (user?.id?.uuid) {
        try {
          const baseUrl = process.env.NODE_ENV === 'development' 
            ? 'http://localhost:3500' 
            : '';
          
          const response = await fetch(`${baseUrl}/api/intercom-hash?user_id=${encodeURIComponent(user.id.uuid)}`, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            credentials: 'include',
          });
          
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          
          const data = await response.json();
          setUserHash(data.hash);
          
        } catch (error) {
          console.error('Error fetching Intercom hash:', error);
        }
      }
    };
    fetchHash();
  }, [user?.id?.uuid]);

  // Initialize Intercom
  useEffect(() => {
    if (!isIntercomLoaded) return;

    if (user?.id?.uuid && userHash) {
      const config = {
        app_id: 'poc51jwi',
        user_id: user.id.uuid,
        name: `${user.attributes.profile.firstName} ${user.attributes.profile.lastName}`.trim(),
        email: user.attributes.email,
        user_hash: userHash
      };

      window.Intercom('boot', config);
    } else {
      window.Intercom('boot', {
        app_id: 'poc51jwi'
      });
    }

    return () => {
      if (isIntercomLoaded) {
        window.Intercom('shutdown');
      }
    };
  }, [user, userHash, isIntercomLoaded]);

  return null;
};

// Add mapStateToProps function
const mapStateToProps = state => ({
  currentUser: state.user.currentUser  // Adjust this path based on your Redux store structure
});

// Modify the export to properly connect to Redux
export default connect(mapStateToProps)(IntercomMessengerComponent);